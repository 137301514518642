import config from '../../config/config.js'
import axios from 'axios'
import {private_url, public_url} from '../axios'
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];

const state = {
    
};

const mutations = {
    
};

const getters = {
    
};

const actions = {
    reegisterUser({},payload){
        // console.log(data, "login:")
        return private_url.post("api/user/add/", payload)
    },
    allUser({}){
        // console.log(data, "login:")
        return private_url.get("api/user/all/")
    }
};

export default {
    namespaced: true,
    state,
    mutations, 
    getters,
    actions,
    plugins
}