import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'Login',
    meta: {
      layout: "login-layout",
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      layout: "dashboard-layout",
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/system/Dashboard.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/business',
    name: 'Business',
    meta: {
      layout: "dashboard-layout",
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/system/Business.vue')
  },
  {
    path: '/user',
    name: 'User',
    meta: {
      layout: "dashboard-layout",
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/system/User.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (!store.getters["auth/getToken"]) {
      router.replace('/');
    } else {
      next();
    }
  } else {
    if (store.getters['auth/getToken']) {
        router.replace('/Dashboard')
      } else {
        next()
      }
  }
  next();
});

export default router
