import config from '../../config/config.js'
import axios from 'axios'
import {private_url, public_url} from '../axios'
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];

const state = {
    
};

const mutations = {
    
};

const getters = {
    
};

const actions = {
    allBusiness({}){
        // console.log(data, "login:")
        return private_url.get("api/business/all/")
    },
    allBusinessCount({}){
        // console.log(data, "login:")
        return private_url.get("api/business/count/")
    }
};

export default {
    namespaced: true,
    state,
    mutations, 
    getters,
    actions,
    plugins
}