import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import user from '../store/modules/user'
import auth from '../store/modules/auth'
import business from '../store/modules/business'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins :[createPersistedState()],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    auth,
    business
  }
})
