import config from '../../config/config.js'
import axios from 'axios'
import {private_url, public_url} from '../axios'
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];

const state = {
    userDetails: [],
    token: "dvhgdhjgdjfehuehjedhdjghjerhriehvs",
};

const mutations = {
    setToken(state,payload){
        state.token=payload
    }
};

const getters = {
    getToken(state){
        return state.token
    }
    
};

const actions = {
    login({},payload){
        // console.log(data, "login:")
        return public_url.post('api/user/login/',payload)
    }
};

export default {
    namespaced: true,
    state,
    mutations, 
    getters,
    actions,
    plugins
}